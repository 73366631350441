<template>
  <div class="route-login right-side min-vh-100 bg-img-mobile">
    <div class="vh-100 px-4 p-md-5">
      <b-button
        type="button"
        variant="link"
        class="px-0 btn-register float-right text-uppercase"
        :href="mainRoute + '/register'"
        target="_blank"
        v-if="!loggedIn"
      >
        Register
        <feather type="arrow-right"></feather>
      </b-button>
      <div class="d-table w-100 h-85 h-md-100">
        <div class="d-table-cell align-middle">
          <b-row>
            <b-col md="10" offset-md="1" class="position-static" v-if="!loggedIn">
              <h1 class="mb-4 mt-5 mt-md-0">
                Login
                <small class="d-block mt-2 font-size-20">In order to proceed with the payment.</small>
              </h1>
              <b-form @submit.prevent="callLogin">
                <b-form-group>
                  <maz-input
                    id="email_phone"
                    type="text"
                    v-model="$v.login_data.username.$model"
                    required clearable
                    placeholder="Email or phone number"
                    :error="$v.login_data.username.$dirty && (!$v.login_data.username.required || !$v.login_data.username.validpe)"
                    :success="$v.login_data.username.$dirty && !$v.login_data.username.$invalid"
                  ></maz-input>
                </b-form-group>
                <div class="invalid-feedback d-block mb-2 mt--3" v-if="!$v.login_data.username.required && $v.login_data.username.$dirty">Please, provide your email or mobile.</div>
                <div class="invalid-feedback d-block mb-2 mt--3" v-if="!$v.login_data.username.validpe && $v.login_data.username.$dirty">Please, provide valid email or mobile.</div>
                <b-form-group>
                  <maz-input
                    id="password"
                    type="password"
                    v-model="$v.login_data.password.$model"
                    required clearable
                    placeholder="Password"
                    :error="$v.login_data.password.$dirty && !$v.login_data.password.required"
                    :success="$v.login_data.password.$dirty && !$v.login_data.password.$invalid"
                  ></maz-input>
                </b-form-group>
                <div class="invalid-feedback d-block mb-2 mt--3" v-if="!$v.login_data.password.required && $v.login_data.password.$dirty">Please, provide your password.</div>
                <div class="mobi-pos-abs no-float-bottom mt-3">
                  <b-form-group>
                    <b-row>
                      <b-col md="4">
                        <loading-button :replace="status.loggingIn === true">
                          <b-button type="submit" variant="custom" :disabled="$v.$invalid || status.loggingIn" pill block>Login</b-button>
                        </loading-button>
                      </b-col>
                    </b-row>
                  </b-form-group>
                  <b-button type="button" variant="link text-uppercase" class="px-0" to="/forgot">
                    Recover Password
                    <feather type="arrow-right"></feather>
                  </b-button>
                </div>
              </b-form>
            </b-col>
            <b-col md="10" offset-md="1" class="d-none d-md-block position-static main list-group py-2 px-4 mt-4" v-if="linkid && !loggedIn">
              <b-row>
                <b-col order-md="2">
                  <h3>Pay using Truzo app</h3>
                  <p>Simply scan the QR code with your mobile phone, login securely using your biometrics, if enabled, and complete the payment.</p>
                  <p><b>Note:</b> You need to have Truzo mobile app installed on your device, visit <a href="#" @click.prevent="$bvModal.show('apple_appstore')">Apple App Store</a> or <a href="#" @click.prevent="$bvModal.show('google_playstore')">Google Play Store</a> to download Truzo app.</p>
                </b-col>
                <b-col cols="12" md="3" class="text-center mt-4 mt-md-1">
                  <qrcode-vue :text="'https://pay.truzo.com/'+ linkid" color="#0a083e" error-level="H" class="qr-code" />
                </b-col>
              </b-row>
              <!-- Modal -->
              <b-modal id="apple_appstore" size="md" centered hide-header hide-footer>
                <button type="button" aria-label="Close" class="close" @click="$bvModal.hide('apple_appstore')">×</button>
                <div class="pt-3 px-md-3">
                  <p>Scan the following QR code or follow the link below to download Truzo app from Apple App Store.</p>
                  <b-row>
                    <b-col cols="12" class="text-center my-3">
                      <qrcode-vue text="https://apps.apple.com/za/app/envisionit-e-escrow/id1441285196" color="#0a083e" error-level="H" class="qr-code" />
                    </b-col>
                    <b-col cols="12" class="text-right mt-0">
                      <b-button type="button" variant="link pb-0" class="px-0" href="https://apps.apple.com/za/app/envisionit-e-escrow/id1441285196" target="_blank">
                        Go to Apple App Store
                        <feather type="arrow-right"></feather>
                      </b-button>
                    </b-col>
                  </b-row>
                </div>
              </b-modal>
              <!-- END | Modal -->
              <!-- Modal -->
              <b-modal id="google_playstore" size="md" centered hide-header hide-footer>
                <button type="button" aria-label="Close" class="close" @click="$bvModal.hide('google_playstore')">×</button>
                <div class="pt-3 px-md-3">
                  <p>Scan the following QR code or follow the link below to download Truzo app from Google Play Store.</p>
                  <b-row>
                    <b-col cols="12" class="text-center my-3">
                      <qrcode-vue text="https://play.google.com/store/apps/details?id=africa.escrow.mobile" color="#0a083e" error-level="H" class="qr-code" />
                    </b-col>
                    <b-col cols="12" class="text-right mt-0">
                      <b-button type="button" variant="link pb-0" class="px-0" href="https://play.google.com/store/apps/details?id=africa.escrow.mobile" target="_blank">
                        Go to Google Play Store
                        <feather type="arrow-right"></feather>
                      </b-button>
                    </b-col>
                  </b-row>
                </div>
              </b-modal>
              <!-- END | Modal -->
            </b-col>
            <b-col md="10" offset-md="1" class="d-block d-md-none position-static main list-group py-2 px-4 my-2" v-if="linkid && !loggedIn">
              <b-row class="rounded box-shadow py-4 px-2">
                <b-col v-if="link && link.transaction && link.transaction._id">
                  <h5 class="mb-0">
                    {{ link.transaction.name }}
                  </h5>
                  <p class="text-uppercase"><small>Reference: {{ link.transaction.reference }}</small></p>
                  <div v-if="!link.transaction.payment || !link.transaction.payment.changeqty">
                    <p class="line-height-1" v-if="total">
                      <b>Total payable</b><br />
                      <small>Includes my portion of the Truzo fees and agent commission, if transaction includes an agent.</small>
                    </p>
                    <p class="mb-0" v-if="total">
                      <strong class="font-size-24 mobile-size-reduce">{{ formatCurrency(total[0], link.transaction.amounts.currency, 2) }} - {{ formatCurrency(total[1], link.transaction.amounts.currency, 2) }}</strong>
                    </p>
                  </div>
                  <div v-else>
                    <p class="mb-0">
                      Transaction total<br />
                      <strong class="font-size-16">Seller allows item quantities to be altered.</strong><br />
                      You need to login in order to view transaction details and select the items you wish to purchase.
                    </p>
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="10" offset-md="1" class="d-block d-md-none position-static main list-group py-2 px-4 mt-0" v-if="linkid && !loggedIn">
              <b-row>
                <b-col class="text-right">
                  <b-button type="button" variant="link text-uppercase pb-0" class="px-0" @click="$bvModal.show('paywithapp')">
                    Pay using Truzo App
                    <feather type="arrow-right"></feather>
                  </b-button>
                </b-col>
              </b-row>
              <!-- Modal -->
              <b-modal id="paywithapp" size="md" centered hide-header hide-footer>
                <button type="button" aria-label="Close" class="close" @click="$bvModal.hide('paywithapp')">×</button>
                <div class="pt-4 pb-3 px-3 px-md-3">
                  <b-row>
                    <b-col order-md="2">
                      <h3>Pay using Truzo app</h3>
                      <p>Simply tap the link below to launch Truzo app, login securely using your biometrics, if enabled, and complete the payment.</p>
                      <p><b>Note:</b> You need to have Truzo mobile app installed on your device, visit <a href="https://apps.apple.com/za/app/envisionit-e-escrow/id1441285196" target="_blank">Apple App Store</a> or <a href="https://play.google.com/store/apps/details?id=africa.escrow.mobile" target="_blank">Google Play Store</a> to download Truzo app.</p>
                    </b-col>
                    <b-col cols="12" md="3" class="text-right mt-0">
                      <b-button type="button" variant="link text-uppercase pb-0" class="px-0" :href="'https://pay.truzo.com/'+ linkid">
                        Launch Truzo App
                        <feather type="arrow-right"></feather>
                      </b-button>
                    </b-col>
                  </b-row>
                </div>
              </b-modal>
              <!-- END | Modal -->
            </b-col>
            <b-col md="10" offset-md="1" class="position-static" v-else-if="loggedIn && !linking && companies && companies.length > 0">
              <h1 class="mb-4 mt-5 mt-md-0">
                Select Profile
                <small class="d-block mt-2 font-size-20">Please, select a profile you'd like to use during this session.</small>
              </h1>
              <b-form @submit.prevent="callSelect">
                <b-form-group>
                  <b-input-group>
                    <b-input-group-prepend>
                      <div class="input-group-text bg-transparent border-right-0">
                        <b-form-radio
                          class="mt-1 check clickable"
                          name="profile"
                          :value="null"
                          v-model="profile"
                        >Individual</b-form-radio>
                      </div>
                    </b-input-group-prepend>
                    <div class="form-control border-left-0 border-right-0"></div>
                    <b-input-group-append>
                      <div class="input-group-text bg-transparent border-left-0">
                        <feather type="user"></feather>
                      </div>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
                <b-form-group v-for="(company, $index) in companies" :key="$index">
                  <b-input-group>
                    <b-input-group-prepend>
                      <div class="input-group-text bg-transparent border-right-0">
                        <b-form-radio
                          class="mt-1 check clickable"
                          name="profile"
                          :value="company._id"
                          v-model="profile"
                        >{{ company.name }}</b-form-radio>
                      </div>
                    </b-input-group-prepend>
                    <div class="form-control border-left-0 border-right-0"></div>
                    <b-input-group-append>
                      <div class="input-group-text bg-transparent border-left-0">
                        <feather type="users"></feather>
                      </div>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
                <div class="mobi-pos-abs no-float-bottom mt-3">
                  <b-form-group>
                    <b-row>
                      <b-col md="4">
                        <loading-button :replace="status.loggingIn === true">
                          <b-button type="submit" variant="custom" pill block>Select</b-button>
                        </loading-button>
                      </b-col>
                    </b-row>
                  </b-form-group>
                </div>
              </b-form>
            </b-col>
            <b-col md="10" offset-md="1" class="position-static main" v-else-if="loggedIn && linking">
              <h1 class="mb-4 d-none d-md-block">
                While you were away
                <small class="d-block mt-2 font-size-20" v-if="this.transaction.links && this.transaction.links.length === 1">This transaction was created.</small>
                <small class="d-block mt-2 font-size-20" v-else>These transactions were created.</small>
              </h1>
              <h2 class="mb-4 mt-4 d-block d-md-none">
                While you were away
                <small class="d-block mt-2 font-size-20" v-if="this.transaction.links && this.transaction.links.length === 1">This transaction was created.</small>
                <small class="d-block mt-2 font-size-20" v-else>These transactions were created.</small>
              </h2>
              <b-list-group class="history-list pb-2">
                <b-list-group-item
                  v-for="(record, index) in this.transaction.links" :key="`transaction-${index}`"
                  @click.prevent="$bvModal.show('accept_' + record._id)"
                  href="#"
                  class="pt-3 pb-2 px-3 rounded box-shadow border-0 mt-3"
                >
                  <feather class="ico-indicator" type="shopping-bag" v-if="record.type.name === 'general'"></feather>
                  <feather class="ico-indicator" type="users" v-else-if="record.type.name === 'project'"></feather>
                  <font-awesome-icon class="ico-indicator" icon="car" v-else-if="record.type.name === 'vehicle'"></font-awesome-icon>
                  <feather class="ico-indicator" type="home" v-else-if="record.type.name === 'rental'"></feather>
                  <i class="ico-indicator bg-gradient p-2" v-else>
                    <img :src="require('@/assets/images/bg-icon-white.png')" class="img-fluid" />
                  </i>
                  <strong class="font-size-18 amount float-right">
                    {{ record.amounts.amount | formatAmount(record.amounts.currency, 2) }}
                  </strong>
                  <span class="desc font-size-14 title-w-60">{{ record.name }}</span>
                  <small class="time color-grey mb-2">
                    By {{ record.session.created_by | formatUser }}, {{ record.session.date_stamp | moment('from', 'now') }}
                  </small>
                  <b-modal :id="'accept_' + record._id" size="md" centered hide-header hide-footer>
                    <h4 class="font-weight-400 color-navy-blue mb-1">{{ record.name }}</h4>
                    <p class="text-uppercase">Transaction Reference: {{ record.reference }}</p>
                    <p class="desc font-size-16 pt-2 mb-0">
                      Link this transaction to:<br />
                      <b-form-group>
                        <b-input-group @click.prevent="acceptAs(record.link, record._id, null)">
                          <b-input-group-prepend>
                            <div class="input-group-text bg-transparent border-right-0">
                              <b-form-radio
                                class="mt-1 check clickable"
                                name="profile"
                                :value="null"
                                v-model="profile"
                              >Individual</b-form-radio>
                            </div>
                          </b-input-group-prepend>
                          <div class="form-control border-left-0 border-right-0"></div>
                          <b-input-group-append>
                            <div class="input-group-text bg-transparent border-left-0">
                              <feather type="user"></feather>
                            </div>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                      <b-form-group v-for="(company, $index) in companies" :key="$index">
                        <b-input-group @click.prevent="acceptAs(record.link, record._id, company._id)">
                          <b-input-group-prepend>
                            <div class="input-group-text bg-transparent border-right-0">
                              <b-form-radio
                                class="mt-1 check clickable"
                                name="profile"
                                :value="company._id"
                                v-model="profile"
                              >{{ company.name }}</b-form-radio>
                            </div>
                          </b-input-group-prepend>
                          <div class="form-control border-left-0 border-right-0"></div>
                          <b-input-group-append>
                            <div class="input-group-text bg-transparent border-left-0">
                              <feather type="users"></feather>
                            </div>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                    </p>
                  </b-modal>
                </b-list-group-item>
              </b-list-group>
              <div class="mobi-pos-abs no-float-bottom mt-3">
                <b-form-group>
                  <b-row>
                    <b-col md="4">
                      <loading-button :replace="status.loggingIn === true">
                        <b-button type="button" variant="custom" pill block @click.prevent="linking = false">Skip</b-button>
                      </loading-button>
                    </b-col>
                  </b-row>
                </b-form-group>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { paymentService } from '@/services'

import { phone } from '@/validators'
import { required, email, or } from 'vuelidate/lib/validators'
import { mapActions, mapGetters, mapState } from 'vuex'

import { apiRoute } from '@/helpers'

export default {
  data () {
    return {
      login_data: {
        username: null,
        password: null,
        recaptcha: null,
        appKey: null
      },
      profile: null,
      selecting: false,
      linking: false,
      showPassword: false,
      link: null
    }
  },
  validations: {
    login_data: {
      username: {
        required,
        validpe: or(phone, email)
      },
      password: {
        required
      }
    }
  },
  computed: {
    ...mapGetters('currency', {
      currencyObject: 'fullObject'
    }),
    ...mapState({
      company: state => state.company,
      payment: state => state.link,
      status: state => state.auth.status,
      txnActions: state => state.txnActions,
      transaction: state => state.transaction
    }),
    loggedIn () {
      return this.status.loggedIn
    },
    companyLoaded () {
      return this.company.status
    },
    actionStatus () {
      return this.txnActions.status
    },
    transactionsLoaded () {
      return this.transaction.status
    },
    companies () {
      if (this.companyLoaded === 'loaded') {
        return this.company.companies
      } else {
        return []
      }
    },
    mainRoute () {
      const _route = apiRoute().replace('services', 'app').replace('pay', 'app')
      return _route
    },
    linkid () {
      if (this.payment && this.payment.type === 'payment' && this.payment.id) {
        return this.payment.id
      } else {
        return false
      }
    },
    fees () {
      if (this.link && this.link._id) {
        if (this.link.transaction && this.link.transaction._id) {
          const _amounts = this.link.transaction.amounts
          const _split = _amounts.fee_payable_by.buyer_percentage / 100
          const _minimum = Math.min.apply(Math, _amounts.fees.map(function(o) { return o.fee })) * _split * 1.15
          const _maximum = Math.max.apply(Math, _amounts.fees.map(function(o) { return o.fee })) * _split * 1.15
          return [_minimum, _maximum]
        } else {
          return false
        }
      } else {
        return false
      }
    },
    commission () {
      if (this.link && this.link._id) {
        if (this.link.transaction && this.link.transaction._id) {
          const _amounts = this.link.transaction.amounts
          const _split = _amounts.split.find(_record => _record.actor === 'Buyer')
          if (_split && _split.agent_comm && _split.agent_comm.length > 0) {
            return _split.agent_comm[0].fee
          } else {
            return 0
          }
        } else {
          return 0
        }
      } else {
        return 0
      }
    },
    total () {
      if (this.link && this.link._id) {
        if (this.link.transaction && this.link.transaction._id) {
          const _amounts = this.link.transaction.amounts
          const _total = this.commission + _amounts.amount
          return [_total + this.fees[0], _total + this.fees[1]]
        } else {
          return false
        }
      } else {
        return false
      }
    }
  },
  created () {
    if (this.loggedIn) {
      if (this.selecting) {
        this.$router.push('/')
      } else {
        this.getAll()
      }
    }
  },
  mounted () {
    if (this.linkid) {
      paymentService.getEmbeddedLink(this.linkid)
        .then(
          link => {
            if (link) {
              this.link = link
            } else {
              this.link = {
                link: 'Payment link is not available. Please, try to generate a new one.'
              }
            }
          },
          error => {
            console.log(error)
          }
        )
    }
  },
  methods: {
    ...mapActions('auth', ['login', 'logout', 'setCompany', 'setIndividual']),
    ...mapActions('company', ['getAll']),
    ...mapActions('txnActions', ['accept']),
    ...mapActions('transaction', ['getLinked']),
    toggleShowPassword: function () {
      if (this.showPassword) {
        this.showPassword = false
      } else {
        this.showPassword = true
      }
    },
    callLogin: function (evt) {
      evt.preventDefault()
      if (this.$isMobile) {
        this.login_data.appKey = this.$appKey
        this.login(this.login_data)
      } else {
        this.$recaptcha('loginregister')
          .then((recaptcha) => {
            this.login_data.recaptcha = recaptcha
            this.login(this.login_data)
          })
      }
    },
    callSelect: function (evt) {
      evt.preventDefault()
      if (!this.profile) {
        this.setIndividual()
        this.$router.push('/')
      } else {
        this.selecting = true
        this.setCompany(this.profile)
      }
    },
    currency: function (transaction) {
      return this.currencyObject(transaction.amounts.currency || 'ZAR')
    },
    acceptAs: function (id, transaction, company) {
      this.accept({ id: id, company: company })
      this.$bvModal.hide('accept_' + transaction)
    },
    formatCurrency: function (value, currency, numDigits) {
      if (typeof value !== 'number') return value
      const _numDigits = numDigits === null || numDigits === undefined ? 2 : numDigits
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency || 'ZAR',
        minimumFractionDigits: _numDigits,
        maximumFractionDigits: _numDigits
      })

      const _rounded = Math.round(value * 1000) / 1000
      const _value = formatter.format(_rounded).replace('ZAR', 'R')
      return _value
    }
  },
  watch: {
    loggedIn: function (loggedIn) {
      if (loggedIn) {
        if (this.selecting) {
          this.selecting = false
          this.$router.push('/')
        } else {
          // this.getLinked()
          this.getAll()
        }
      }
    },
    companyLoaded: function (status) {
      if (status === 'loaded') {
        if (!this.company.companies || this.company.companies.length === 0) {
          if (this.linking === false) {
            this.$router.push('/')
          }
        }
      } else if (status === 'not-found' && this.linking === false) {
        this.$router.push('/')
      } else if (status === 'failed') {
        // TODO: Display error
      }
    },
    actionStatus: function (status) {
      console.log(status)
      if (status === 'saved') {
        this.getLinked()
      }
    },
    transactionsLoaded: function (status) {
      if (status === 'loaded') {
        console.log(this.transaction.links.length)
        if (this.transaction.links && this.transaction.links.length > 0) {
          this.linking = true
        } else {
          this.linking = false
        }
      } else if (status === 'not-found') {
        this.linking = false
      }
    },
    linking: function (value) {
      if (value === false) {
        if (this.companyLoaded === 'loaded') {
          if (!this.company.companies || this.company.companies.length === 0) {
            this.$router.push('/')
          }
        } else if (this.companyLoaded === 'not-found') {
          this.$router.push('/')
        }
      }
    },
    'login_data.username': function (value) {
      this.login_data.username = value.trim()
    }
  }
}
</script>
